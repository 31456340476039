import React from "react";
import Page from "../../components/Page";
import OHIFGalleryHeader from "../../components/OHIFGalleryHeader";
import SEO from "../../components/SEO";

function BehindTheScene() {
  return (
    <Page>
      <SEO title="Modes | OHIF" />
      <main className="pt-16 ">
        <div>
          <div>
            <OHIFGalleryHeader />
          </div>
          <div className="flex flex-col px-12 py-16 space-y-8 text-white">
            <h2 className="text-5xl text-blue-100">Submit Your Mode</h2>
            <div>
              Please use the following Google form to submit the mode that you
              have developed. OHIF team will review your information, and make
              your mode appear in OHIF's mode gallery.
            </div>
            <p>
              OHIF website automatically fetches the latest version of your
              Github README.md file and uses it to generate a mode description.
              Note that in order to have a proper formatting for your images,
              please make sure that your images have correct width and height in
              your markdown file. In addition, do not refer to the images with a
              relative path (e.g. `![](image.png)`), instead, use a direct URL
              (e.g.
              `![](https://raw.githubusercontent.com/OHIF/Viewers/fix/tracking-qa/modes/longitudinal/assets/workflow.png)`)
              that you obtain after uploading your images to Github.
            </p>

            <div className="flex justify-center mt-8 ">
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSf8FdMruUMhDRrGNcLm5sjRiofLObpPFTogbgH7W1wDqEiy3w/viewform?embedded=true"
                width="1000px"
                height="1300px"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                className="p-4"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}

export default BehindTheScene;
